import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation, booleanAttribute, input } from '@angular/core';
import { RouterLinkDirective } from '@hyundai/ng-common-lib';
import { M000C010ImageComponent } from '../../000-primitives/m000-c010-image/m000-c010-image.component';
import { ImageAiFocus } from '../../000-primitives/m000-c010-image/m000-c010-image.interface';
import type { M040C070ContactPersonData } from './m040-c070-contact-person.interface';

@Component({
    selector: 'ui-m040-c070-contact-person',
    standalone: true,
    templateUrl: './m040-c070-contact-person.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [RouterLinkDirective, M000C010ImageComponent, NgClass],
})
export class M040C070ContactPersonComponent {
    data = input.required<M040C070ContactPersonData>();
    showPhone = input(true, { transform: booleanAttribute });
    showMail = input(true, { transform: booleanAttribute });
    isFirstView = input.required({ transform: booleanAttribute });

    ImageAiFocus = ImageAiFocus;
}
