<figure class="flex max-w-[378px] items-start gap-12 md:items-center">
    <ui-m000-c010-image
        class="block size-[96px] flex-shrink-0 self-start"
        [classList]="'w-full size-[96px] aspect-square rounded-full'"
        [image]="{
            image: data().image.url,
            desktop: { width: 96, height: 96 },
            tablet: { width: 96, height: 96 },
            mobile: { width: 96, height: 96 },
        }"
        [priority]="isFirstView()"
        [alt]="data().image.alt ?? data().name"
        [focusAi]="ImageAiFocus.FACE"
        highRes
    />
    <div class="flex-1 overflow-hidden">
        <figcaption class="font-headline text-16 font-medium leading-27 text-black">{{ data().name }}</figcaption>
        @if (data().position) {
            <span class="leading-21 text-back -mt-4 font-text text-14">{{ data().position }}</span>
        }
        @if (showPhone() && data().phone) {
            <a
                class="block font-headline text-16 font-medium leading-27 text-accent hover:text-blue"
                [hyundaiRouterLink]="'tel:' + data().phone"
            >
                {{ data().phone }}
            </a>
        }
        @if (showMail() && data().email) {
            <a
                class="block truncate font-headline text-16 font-medium leading-27 text-accent hover:text-blue"
                [ngClass]="{ '-mt-4': data().phone }"
                [hyundaiRouterLink]="'mailto:' + data().email"
            >
                {{ data().email }}
            </a>
        }
    </div>
</figure>
